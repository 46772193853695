<template>
  <div>
    <v-container fluid>

      <div class="main">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="9">

            <v-card class="shadow">

              <v-overlay :value="isLoading"
                         absolute
                         color="white"
                         opacity="0.9">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>

              </v-overlay>

              <div class="d-flex align-center justify-space-between pa-4">

                <h3 class="font-weight-medium">
                  Effectuer un paiement classique
                </h3>

                <v-spacer/>

                <v-btn :to="'/partner-vouchers/store/'+$route.params.id"
                       depressed
                       exact
                       icon
                       color="gifty"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

              </div>

              <v-divider/>

              <v-card-text>

                <p class="text-center px-2 pt-4 px-lg-16 mb-10">
                  Scannez le code du bon d'achat de l'utilisateur ou recopiez le code sur le bon
                  d'achat.
                </p>

                <v-alert v-model="isError" border="left" dismissible type="error">
                  {{ errorMessage }}
                </v-alert>

                <v-tabs
                    v-model="tab"
                    active-class="gifty"
                    background-color="transparent"
                    centered
                    color="white"
                    hide-slider
                    icons-and-text
                >
                  <v-tab class="text-none font-weight-medium rounded-lg">
                    Recopiez le code
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-tab>

                  <v-tab class="text-none font-weight-medium rounded-lg">
                    Scannez le QR code
                    <v-icon>mdi-qrcode-scan</v-icon>
                  </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab">


                  <v-tab-item>
                    <v-card flat>
                      <v-card-text class="pa-lg-10 pa-4">

                        <p class="font-weight-medium pt-4 pt-lg-0">
                          Code du bon d'achat <span class="red--text">*</span>
                        </p>

                        <v-textarea v-model="code"
                                    clearable
                                    outlined
                                    clear-icon="mdi-close-circle"
                                    hide-details
                                    placeholder="Veuillez saisir le code du bon d'achat ici..."
                                    rows="2">

                        </v-textarea>

                        <div class="d-flex mt-5">

                          <v-spacer/>

                          <v-btn :dark="!!code"
                                 :disabled="!code"
                                 color="gifty"
                                 depressed
                                 @click="scan">
                            <v-icon left>mdi-rotate-left</v-icon>
                            Vérifier
                          </v-btn>

                        </div>

                      </v-card-text>
                    </v-card>
                  </v-tab-item>


                  <v-tab-item>
                    <v-card flat>
                      <v-card-text class="pa-lg-10 pa-4">
                        <qrcode-stream @decode="onDecode"/>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>


                </v-tabs-items>


              </v-card-text>

            </v-card>
          </v-col>

        </v-row>
      </div>


      <v-dialog v-if="result"
                v-model="dialog"
                persistent
                scrollable
                width="500">
        <v-card :disabled="btnLoading" :loading="btnLoading">

          <v-card-title>
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="pa-8">

            <div v-if="success" class="text-center">

              <lottie :options="defaultOptions" :height="200" :width="200"/>

              <h1 class="pb-5 gifty--text">
                Succès !
              </h1>

              <p>
                Cette opération a été enregistrée avec succès.
              </p>

              <div class="text-center mt-5">
                <v-btn color="gifty"
                       dark
                       text
                       class="v-btn--active"
                       depressed
                       @click="$refs.printContent.printPdf(consumedVoucher)">
                  <v-icon left>mdi-cloud-print-outline</v-icon>
                  Imprimer
                </v-btn>
              </div>

              <div class="d-flex mt-10 justify-space-between">
                <v-btn color="gifty"
                       text
                       exact
                       :to="'/partner-vouchers/store/' + $route.params.id">
                  <v-icon left>mdi-arrow-left</v-icon>
                  Retour
                </v-btn>
                <v-spacer/>
                <v-btn color="gifty"
                       depressed
                       dark
                       @click="[dialog = false,success = false,code=null]">
                  <v-icon left>mdi-plus</v-icon>
                  Nouveau
                </v-btn>
              </div>

            </div>

            <div v-else class="text-center">


              <v-avatar color="gifty" size="80">
                <v-icon dark size="40">mdi-sticker-check-outline</v-icon>
              </v-avatar>

              <h1 class="py-5 gifty--text">{{ CurrencyFormatting(result.amount) + '.00' }} DZD</h1>


              <v-simple-table class="table-border rounded-lg">
                <tbody>
                <tr>
                  <th>
                    <v-icon left color="primary">mdi-tag</v-icon> Service
                  </th>
                  <td>{{ result.service.name }}</td>
                </tr>
                <tr class="grey lighten-4">
                  <th>
                    <v-icon left color="green">mdi-truck</v-icon> Fournisseur
                  </th>
                  <td>{{ result.supplier.name }}</td>
                </tr>
                <tr>
                  <th>
                    <v-icon left color="red">mdi-calendar-clock</v-icon> Date d'expiration
                  </th>
                  <td>{{ getHumanDate(result.expiration_date) }}</td>
                </tr>
                </tbody>
              </v-simple-table>

              <div class="d-flex mt-10 justify-space-between">
                <v-btn color="red" class="rounded-lg" text @click="dialog=false">
                  <v-icon left>mdi-close</v-icon>
                  Fermer
                </v-btn>

                <v-spacer/>
                <v-btn :loading="btnLoading"
                       color="gifty"
                       class="rounded-lg"
                       dark
                       depressed
                       @click="save">
                  <v-icon left>mdi-cloud-refresh</v-icon>
                  Converter
                </v-btn>
              </div>

            </div>

          </v-card-text>
        </v-card>
      </v-dialog>

      <PrintContent ref="printContent"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import {QrcodeStream} from 'vue-qrcode-reader'
import moment from "moment";
import lottie from 'vue-lottie';
import successIcon from '@/assets/success-lottie.json';
import PrintContent from "@/views/partner_vouchers/components/PrintContent";

export default {
  components: {
    PrintContent,
    lottie,
    QrcodeStream
  },
  data() {
    return {
      tab: null,
      isLoading: false,
      btnLoading: false,
      code: null,

      isError: false,
      errorMessage: null,
      dialog: false,
      result: null,
      success: false,
      defaultOptions: {
        animationData: successIcon,
        loop: false,
        autoplay: true,
      },
      consumedVoucher: {},
    };
  },
  methods: {
    scan() {
      this.success = false
      this.isLoading = true
      this.isError = false
      HTTP.get('/v1/partner-vouchers/scan', {
        params: {
          code: this.code,
          store_id: this.$route.params.id,
        }
      }).then(res => {
        this.result = res.data.data
        this.isLoading = false
        this.dialog = true
      }).catch(err => {
        this.isLoading = false
        if (err && err.response && err.response.data.message) {
          this.isError = true
          this.errorMessage = err.response.data.message
        }
        if (err.response.status === 429) {
          this.isError = true
          this.errorMessage = 'Vous avez dépassé le nombre de tentatives autorisées!'
        }
        console.log(err)
      })
    },
    save() {
      this.btnLoading = true
      let data = {
        store_id: this.$route.params.id,
        code: this.code,
      }
      HTTP.post('/v1/partner-vouchers/store', data).then((res) => {
        this.btnLoading = false
        this.success = true
        this.code = null
        this.consumedVoucher = res.data.data
      }).catch(err => {
        this.btnLoading = false
        this.dialog = false
        if (err && err.response && err.response.data.message) {
          this.isError = true
          this.errorMessage = err.response.data.message
        }
        console.log(err)
      })
    },

    getHumanDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },

    onDecode(result) {
      this.code = result
      this.scan()
    },
  }
};
</script>

<style scoped></style>
